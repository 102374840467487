<template>
    <div id="support" class="page-content">
        <div class="mdl-grid">
            <header class="header-grey">
                <div class="header-grey-row">
                    <span class="mdl-layout__title wrapper-title">
                        <span class="pre">{{ $t('support.title')}}</span>
                    </span>
                </div>
            </header>
        </div>
        <div class="mdl-grid">

            <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone" v-if="isSent === false">

                <div class="mdl-grid">

                    <div class="mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-support-info">
                        <img class="image-support" src="../assets/images/support.png" alt="Dembora support">
                        <p class="text-support">{{ $t('support.text')}}</p>
                    </div>

                    <div class="mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-support-form">

                        <form @submit.prevent="handleSubmit">

                            <span class="material-icons support-icon">contact_support</span>

                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                                <label class="mdl-textfield__label" for="contact">{{ $t('support.contact') }}</label>
                                <input class="mdl-textfield__input input-support" id="contact" maxlength="80" v-model="contact" size="20"
                                    type="text" required/>
                            </div>

                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                                <label class="mdl-textfield__label" for="subject">{{ $t('support.subject') }}</label>
                                <input class="mdl-textfield__input input-support" id="subject" maxlength="80" v-model="subject" size="20"
                                    type="text" required/>
                            </div>

                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                                <label class="mdl-textfield__label" for="description">{{ $t('support.description') }}</label>
                                <textarea class="mdl-textfield__input textarea-support" id="description" v-model="description"
                                        required></textarea>
                            </div>

                            <div class="wrapper-submit">
                            <button type="submit" name="submit" id="submit" class="mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect submit-support">
                                {{$t('support.send')}}
                            </button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>

            <div class="mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-feedback" v-else>
                <span class="material-icons image-support-feedback">mark_email_read</span>
                <p class="support-feedback">{{ $t('support.confirmation')}}</p>
            </div>

            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </div>
    </div>
</template>

<script>
    import VueElementLoading from 'vue-element-loading'
    import {mapActions, mapState} from "vuex";

    export default {
        name: "Support",
        data() {
            return {
                subject: '',
                description: '',
                contact: '',
                isSent: false
            }
        },
        computed: {
            ...mapState({
                show: state => state.salesforce.requesting,
                account: (state) => state.account.account
            }),
        },
        methods: {
            ...mapActions('salesforce', ['supportCase']),
            handleSubmit() {
                try {
                    this.supportCase({
                        'orgid': '00D2p000000Pp9f',
                        '00N2p000007jWll': 'Dembora',
                        'company': this.account.name,
                        'email': this.account.email,
                        'name': this.contact,
                        'subject': `[Dembora] ${this.subject}`,
                        'origin':'Web',
                        'description': this.description,
                    })

                    this.$toast.success(this.$i18n.t('support.form'))
                    this.isSent = true
                } catch (error) {
                    this.$toast.error(error.message)
                }
            },
        },
        components: {
            VueElementLoading
        }
    }
</script>
